// extracted by mini-css-extract-plugin
export var freeBox__cB7J = "PlasmicFeatureCard-module--freeBox__cB7J--FcQIS";
export var freeBox__ccSk8 = "PlasmicFeatureCard-module--freeBox__ccSk8--+xrO0";
export var freeBox__daB6M = "PlasmicFeatureCard-module--freeBox__daB6M--Dp-4a";
export var freeBox__le8QG = "PlasmicFeatureCard-module--freeBox__le8QG--E6fsU";
export var freeBoxlong__cB7Jp3UT2 = "PlasmicFeatureCard-module--freeBoxlong__cB7Jp3UT2--rr4jW";
export var freeBoxlong__daB6MP3UT2 = "PlasmicFeatureCard-module--freeBoxlong__daB6MP3UT2--Lfo5E";
export var freeBoxlong__le8QGp3UT2 = "PlasmicFeatureCard-module--freeBoxlong__le8QGp3UT2--nrsWz";
export var root = "PlasmicFeatureCard-module--root--Eqihd";
export var rootlong = "PlasmicFeatureCard-module--rootlong--c-VTr";
export var slotTargetDescription = "PlasmicFeatureCard-module--slotTargetDescription--JPlNt";
export var slotTargetDescriptionlong = "PlasmicFeatureCard-module--slotTargetDescriptionlong--74-l5";
export var slotTargetTitle = "PlasmicFeatureCard-module--slotTargetTitle--i6I9w";
export var svg___15AOs = "PlasmicFeatureCard-module--svg___15AOs--s1dFp";
export var svg___311Tm = "PlasmicFeatureCard-module--svg___311Tm--80DdZ";