// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--3eW1G";
export var avatar = "PlasmicTestimonial-module--avatar--j84fQ";
export var freeBox__kcrai = "PlasmicTestimonial-module--freeBox__kcrai--4d+Jx";
export var freeBox__ydt7G = "PlasmicTestimonial-module--freeBox__ydt7G--8qfLq";
export var img = "PlasmicTestimonial-module--img--LLYpw";
export var root = "PlasmicTestimonial-module--root--b0trQ";
export var svg = "PlasmicTestimonial-module--svg--HsaxC";
export var text__tgtvj = "PlasmicTestimonial-module--text__tgtvj--ERbe2";
export var text__uxjPq = "PlasmicTestimonial-module--text__uxjPq--yyqPu";
export var text__zulGy = "PlasmicTestimonial-module--text__zulGy--YdVkJ";